<template>
    <div class="mt-n2">
        <template v-for="field in fields">
            <template v-if="renderField(field)">
                <template v-if="field.new_group">
                    <b-row :key="'row-'+field.id" :class="field.rowclass ? field.rowclass : 'mt-2'" :style="field.rowstyle">
                        <template v-for="groupField in fields">
                            <template v-if="renderField(groupField)">
                                <template v-if="groupField.new_group === field.new_group || groupField.group === field.new_group">
                                    <b-col :key="'col-' + groupField.id" :cols="groupField.cols">
                                        <FormField :key="'field-' + groupField.id"
                                                   :field="groupField" :data="record" :formErrors="formErrors" :readonly="readonly"
                                                   :userLanguage="userLanguage" :languages="languages"
                                                   v-on:create-relation="$emit('create-relation',$event)"
                                                   v-on:update-relation="$emit('update-relation',$event)"
                                                   v-on:input="onInput"/>
                                    </b-col>
                                </template>
                            </template>
                        </template>
                    </b-row>
                </template>
                <template v-else-if="!field.group">
                    <b-row :key="'row-'+field.id" :class="field.rowclass ? field.rowclass : 'mt-2'" :style="field.rowstyle">
                        <b-col :key="'col-' + field.id" :cols="field.cols">
                            <FormField :key="'field-' + field.id"
                                       :field="field" :data="record" :formErrors="formErrors" :readonly="readonly"
                                       :userLanguage="userLanguage" :languages="languages"
                                       v-on:create-relation="$emit('create-relation',$event)"
                                       v-on:update-relation="$emit('update-relation',$event)"
                                       v-on:input="onInput"/>
                        </b-col>
                    </b-row>
                </template>
            </template>
        </template>
        <div v-if="generalError" class="general-error">{{ generalError }}</div>
    </div>
</template>

<script>
import FormField from "./fields/FormField";

export default {
    name: "RecordForm",
    components: {
        FormField,
    },
    props: {
        type: {type: String, default: null},
        fields: {type: Array, default: null},
        data: {type: Object, default: null},
        readonly: {type: Boolean, default: false},
        formErrors: {type: Object, default: null},
        generalError: {type: String, default: null},
        userLanguage: {type: String, default: 'en'},
        languages: {type: Array, default: null},
    },
    data() {
        return {
            record: {},
            sendModelData: false,
        }
    },
    created() {
        for (const field of this.fields) {
            if (this.renderField(field)) {
                if (this.data && field.type === 'TRANSLATABLE') {
                    let languages = {};
                    for (const language of this.languages) {
                        languages[language] = this.data[field.name] && this.data[field.name][language] ? this.data[field.name][language] : '';
                    }
                    this.record = {
                        [field.name]: languages,
                        ...this.record
                    }
                } else if (this.data && Object.prototype.hasOwnProperty.call(this.data, field.name)) {
                    switch (field.type) {
                        case 'BOOL':
                            this.record = {
                                [field.name]: parseInt(this.data[field.name]) ? 1 : 0,
                                ...this.record
                            }
                            break;
                        default:
                            this.record = {
                                [field.name]: this.data[field.name],
                                ...this.record
                            }
                            break;
                    }
                } else if (field.required || field.editinform) {
                    switch (field.type) {
                        case 'BOOL':
                            this.record = {
                                [field.name]: (!field.default || field.default === '0' || field.default === 'false') ? '0' : '1',
                                ...this.record
                            }
                            break;
                        case 'NUMERIC':
                            this.record = {
                                [field.name]: field.default ? field.default : 0,
                                ...this.record
                            }
                            break;
                        case 'TRANSLATABLE':
                            this.record = {
                                [field.name]: field.default ? field.default : {},
                                ...this.record
                            }
                            break;
                        default:
                            this.record = {
                                [field.name]: field.default ? field.default : null,
                                ...this.record
                            }
                            break;
                    }
                }
            }
        }
        this.onInput();
    },
    methods: {
        checkInput() {
            for (const field of this.fields) {
                if (this.renderField(field) && field.required) {
                    switch (field.type) {
                        case 'BOOL':
                        case 'NUMERIC':
                            if (this.record[field.name] === null || this.record[field.name] === "") {
                                return false;
                            }
                            if (field.notempty && parseFloat(this.record[field.name]) === 0) {
                                return false;
                            }
                            if (field.min && this.record[field.name] < field.min) {
                                return false;
                            }
                            if (field.max && this.record[field.name] > field.max) {
                                return false;
                            }
                            break;
                        case 'ENUM':
                            if (!field.options.some(option => option.value === this.record[field.name])) {
                                return false;
                            }
                            break;
                        case 'RELATION':
                            if (!this.record[field.name] || !this.record[field.name]['id']) {
                                return false;
                            }
                            break;
                        case 'TRANSLATABLE': {
                            let valid = false;
                            if (this.record[field.name]) {
                                this.languages.forEach(language => {
                                    if (this.record[field.name][language] && this.record[field.name][language].trim() !== '') {
                                        valid = true;
                                    }
                                });
                            }
                            if (!valid) {
                                return false;
                            }
                            break;
                        }
                        default:
                            if (this.record[field.name] === null || this.record[field.name].trim() === '') {
                                return false;
                            }
                            break;
                    }
                }
            }
            return true;
        },
        getRecordData(modelFormat) {
            let recordData = {};
            for (const field of this.fields) {
                if (this.renderField(field)) {
                    switch (field.type) {
                        case 'BOOL':
                            recordData[field.name] = parseInt(this.record[field.name]) ? 1 : 0;
                            break;
                        case 'DATE':
                            recordData[field.name] = this.record[field.name] ? this.record[field.name] : null;
                            break;
                        case 'RELATION':
                            if (modelFormat) {
                                let idcolumn = field.idcolumn ? field.idcolumn : 'id';
                                recordData[field.name] = this.record[field.name] ? this.record[field.name][idcolumn] : null;
                            } else {
                                recordData[field.name] = this.record[field.name] ? this.record[field.name] : null;
                            }
                            break;
                        case 'TRANSLATABLE':
                            this.languages.forEach(language => {
                                if (this.record[field.name]) {
                                    if (!recordData[field.name]) {
                                        recordData[field.name] = {};
                                    }
                                    recordData[field.name][language] = this.record[field.name][language];
                                }
                            })
                            break;
                        default:
                            recordData[field.name] = this.record[field.name];
                            break;
                    }
                }
            }
            return recordData;
        },
        onInput() {
            if (!this.readonly) {
                if (this.checkInput()) {
                    this.$emit('model-data', this.getRecordData(true));
                    this.sendModelData = true;
                } else if (this.sendModelData) {
                    this.$emit('model-data', null);
                    this.sendModelData = false;
                }
                this.$emit('input', this.getRecordData(false));
            }
        },
        renderField(field) {
            if ((field.hideindetailform && this.type === 'detail') ||
                (field.hideinupdateform && this.type === 'update')) {
                return false;
            }
            if ((field.showindetailform && this.type === 'detail')) {
                return true;
            }
            return (field.editinform || field.showinform);
        },
    }
}
</script>

<style scoped>
.general-error {
    color: red;
    text-align: center;
    margin-top: 5px;
}
</style>